@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,900i&display=swap');
$playfair: 'Playfair Display', serif;
$heebo: 'Heebo', sans-serif;
html, body {
	height: 100%;
}
.blue{
	background-color: blue;
}
.show{
	display: block !important;
}
body {
	margin: 0;
	padding: 0; 
	overflow-x: hidden;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
.parsley-required{
	color: red;
    font-size: 11px;
}
ul {
	padding-left: 0;
}
li { 
	list-style: none;
}
.sidenav-button{
	cursor: pointer;
}
.shop{
	position: fixed;
    z-index: 31;
    background-color: white;
    padding: 16px 25px;
    border-radius: 8px 0 0 8px;
    right: 0;
    top: 45px;
    font-weight: bold;
	font-size: 12px;
	box-shadow: 0px 0px 27px -12px rgba(0,0,0,0.75);
	i{
		margin-right: 10px;
	}
	&:hover{
		padding: 16px 40px;
		transition: .5s;
	}
}
a, a > span {
	position: relative;
	color: inherit;
	text-decoration: none;
	line-height: 24px;
	&:before, &:after {
	  content: '';
	  position: absolute;
	  transition: transform .5s ease;
	}
  }
  .effect-2 {
	padding: 10px;
	&:before, &:after {
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	  border-style: solid;
	  border-color: white;
	}
	&:before {
	  border-width: 2px 0 2px 0;
	  transform:  scaleX(0);
	}
	&:after {
	  border-width: 0 2px 0 2px;
	  transform:  scaleY(0);
	}
	&:hover:before, &:hover:after {
	  transform:  scale(1, 1);
	}
  }
//   .slick-arrow{
// 	background: none;
//     position: absolute;
//     z-index: 50;
//     top: 50%;
//     transform: translateY(-50%);
//     border: none;
//     color: rgba(0,0,0,.3);
//     transition: color 150ms;
// 	font-size: 40px;
// 	background-color: #909090;
//     border-radius: 50%;
//     width: 60px;
//     opacity: 0.4;
// }
.slick-prev{
	left: 10px;
	&:hover{
		color: white;
	}
}
// .far{
// 	color: white;
// }
.slick-next{
	color: white;
	left: 2px;
    position: relative;
	top: unset;
	font-size: 23px;
	display: unset;
	height: unset;
	width: unset;
	&:hover{
		color: white;
	}
}
.scrolled-nav {
	height: 60px !important;
	background-color: black !important;
	top: 0 !important;
	.navbar-brand img{
		max-height: 54px !important;
	  }
	.menu ul{
		height: 60px !important;
	}
	.menu-button {
		margin: 13px 20px;
	}
}
.global-nav{
	background-color: transparent;
	top: 25px;
	height: 100px;
	width: 100%;
	position: fixed;
	z-index: 30;
	transition: height .5s, line-height .5s, background-color .5s, margin .5s;
	.navbar-brand {
		padding: 0;
		img {
			max-height: 90px;
			transition: .5s;
				&:hover{
				opacity: .5;
				}
		}
	}
	.menu{
		ul{
			display: flex;
			justify-content: space-around;
			height: 100px;
			font-family: $heebo;
			a{
				line-height: 30px;
				margin: auto 0;
				padding: 0 16px;
				color: white;
				text-transform: uppercase;
				// font-family:
				font-size: 12px;
				cursor: pointer;
			}
		}
	}
}
.menu-button{
	color: white;
	margin: 32px 20px;
	display: none;
	font-size: 25px;
}
.slick-dotted.slick-slider{
	margin-bottom: unset !important;
}
.home{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 100vh;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
		.title{
			position: absolute;
			z-index: 10; 
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			color:white;
			font-family: $playfair;
			font-size: 50px;
			font-weight: bold;
		}
		
	}
	.about{
		padding: 100px 0 200px 0;
		position: relative;
		&::before{
			background-color: #EBEBEB;
			position: absolute;
			top: 0;
			bottom:0;
			left:0;
			right: 67px;
			content:"";
			opacity: .7;
			z-index:0;
		}
		.container{
			position: relative;
			z-index: 1;
			display: flex;
			.title{
				font-family: $playfair;
				font-weight: bolder;
				color: black;
				font-size: 50px;
				flex-basis: 20%;
				margin: auto 0;
			}
			.description{
				font-family: 'Heebo', sans-serif;
				flex-basis: 80%;
				color:#797979;
				margin: auto 0;
				padding-left:200px;
				line-height: 25px;
				.learn{
					margin: 20px 0;
					a{
						font-weight: bold;
						color: black;
						&:hover{
							border-bottom: 1px solid black;
						}
					}
				}
			}
		}
	}
	.container{
		.gallery{
			margin-top: -70px;
			margin-bottom: 250px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			height: 450px; 
			grid-gap: 35px;
			.image{
				box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.75);
				position: relative;
				.gal-image{
					height: 100%;
					position: absolute;
					width: 100%;
					object-fit: cover;
					object-position: center;
					filter: brightness(50%);
				}
				.content{
					margin: auto;
					text-align: center;
					position: absolute;
					top: 50%;
					left: 20px;
					right: 20px;
					color: white;
					transform: translateY(-50%);
					img{
						max-height: 65px;
						margin: 50px;
					}
					.description{
						font-size: 20px;
						font-family: $heebo;
						margin-bottom: 32px;
					}
					.button-franchise{
						padding: 13px 29px;
						color: white;
						background-color: black;
						border: 0;
						border: solid 1px black;
						display: none;
						&:hover{
							background-color: #F0F0F0;
							color: black;
						}
					}
					.contain{
						position: relative;
						.learn-more{	
							color: white;
							font-weight: bold;
							opacity:0;
							cursor: pointer;
							i{
								margin-left: 10px;
							}
						}
						.tooltiptext{
							visibility: hidden;
							background-color: #EAEAEA;
							color: black;
							text-align: center;
							border-radius: 6px;
							top: 33px;
							padding: 20px;
							position: absolute;
							z-index: 1;
							font-size: 12px;
						}
						&:hover{
							.tooltiptext {
								visibility: visible;
							}
						}
					}
				}
				&:nth-child(3){
					.content{
						.button-franchise{
							display: initial;
						}
					}
					.contain{
						margin-top: 20px;
					}
				}
				&:hover{
					.content{
						.learn-more{
							opacity: 1;
						}
					}
					.gal-image{
						filter: brightness(70%);
					}
				}
			}
		}
	}
	.home-offers{
		height: 350px;
		display: flex;
		position: relative;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&::before{
			position: absolute;
			top: 0;
			bottom:0;
			left:0;
			right:0;
			content:"";
			background: #F0F0F0;
			opacity: .8;
			z-index:0;
		}
		.container{
			position: relative;
			z-index: 1;
			margin: auto;
			text-align: center;
			.title{
				font-size: 50px;
				font-weight: bolder;
				color: black;
				font-family: $playfair;
			}
			.desc{
				font-family: $heebo;
				color: #6D6E70;
				margin: 20px 0;
			}
			a{
				color: black;
				font-weight: bold;
				i{
					margin-left: 10px;
				}
				&:hover{
					border-bottom: solid 1px black;
				}
			}
		}
	}
}
.news-home{
	margin: 100px 0; 
	.container{
		.title{
			padding: 20px 0;
			font-size: 50px;
			font-weight: bold;
			font-family: $playfair;
			color: black;
		}
		.news-section{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			padding: 20px 0px;
			grid-gap: 25px;
			.article{
				a{
					img{
						height: 250px;
						width: 100%;
						object-fit: cover;
						object-position: center;
					}
					&:hover{ 
						opacity: .8;
					}
				
					.news-title{
						padding: 20px 0;
						font-size: 20px;
						font-weight: bolder;
						color: black;
						font-family: $heebo;
						margin-left: 10px;
					}
					.description{
						font-size: 14px;
						color: #797979;
						font-family: $heebo;
						margin-left: 10px;
					}
					.learn{
						margin: 20px 10px;
						.learn-more{
							font-weight: bold;
							&:hover{
								border-bottom: 1px solid black;
							}
						}
					}
				}
				&:hover{
					background-color: #EAEAEA;
				}
			}
		}
	}
}
.about-page{
	position: relative;
	.main-banner{
		position: relative;
		.banner{
			height: 100vh;
			background-position: center;
			background-size: cover;
			display: flex;
			position: relative;
			width: 100%;
			align-items: center;
			&::before{
				position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
			}
		}
	}
	.title{
		position: absolute;
		z-index: 10; 
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		color:white;
		font-family: $playfair;
		font-size: 83px;
		font-weight: bold;
		text-align: center;
		.subtitle{
			font-size: 14px;
			font-weight: lighter;
			font-family: $heebo;
			margin: 20px 0; 
		}
		img{
			height: 70px;
			margin-top: -60px;
			opacity: .5;
		}
		.rotate{
			transform: rotate(180deg);
		}
	}
	.nav{
		ul{
			display: flex;
		width: 100%;
		justify-content: space-around;
			li{
				list-style: none;
				font-size: 15px;
				padding: 20px 0;
				width: 100%;
				text-align: center;
				border-top: solid 12px white;
				text-transform: uppercase;
				cursor: pointer;
				&:hover, &.active{
					border-top: solid 12px #00AEEF;
				}
			}
		}
	}
	.history{
		margin: 90px 0;
		.main-title{
			text-transform: uppercase;
			color: #C3C3C3;
			font-weight: bold;
			letter-spacing: 2px;
			
		}
		.subtitle{
			font-size: 38px;
			font-family: $playfair;
			font-weight: 700;
		}
		.desc{
			line-height: 25px;
			color: #797979;
			column-count: 2;
			column-gap: 100px;
			margin: 60px 0 0 200px;
		}
	}
	.banner{
		height: 300px;
		width: 100%;
		background-position: center;
		background-size: cover;
	}
	.mission{
		background-color: black;
		height: 500px;
		.container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			.main-title{
				text-transform: uppercase;
				color: #C3C3C3;
				font-weight: bold;
				letter-spacing: 2px;
			}
			.quote{
				color: #FFFFFF;
				font-size: 40px;
				font-family: $playfair;
				font-weight: 700;
				img{
					height: 55px;
					margin-left: -75px;
					margin-top: -44px;				
				}
			}
		}
	}
	.vision{
		background-color: #F7F7F7;
		height: 500px;
		.container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			.main-title{
				text-transform: uppercase;
				color: #C3C3C3;
				font-weight: bold;
				letter-spacing: 2px;
			}
			.quote{
				color: black;
				font-size: 40px;
				font-family: $playfair;
				font-weight: 700;
				img{
					height: 55px;
					margin-left: -75px;
					margin-top: -44px;				
				}
			}
		}
	}
	.news-gallery{
		margin: 120px 0;
		display:grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 40px;
		.news{
			img{
				width: 100%;
				height: 300px;
				object-fit: cover;
				object-position: center;
			}
			.detail{
				margin: 15px 0;
				.s-title{
					font-size: 27px;
					font-family: $heebo;
					font-weight: bold;
				}
				.desc{
					line-height: 25px;
					color: #797979;
					font-family: $heebo;
					margin: 10px 0;
				}
				a{
					font-weight: bold;
					padding-bottom: 5px;
					font-family: $heebo;
					i{	
						margin-left: 10px;
					}
					&:hover{
						border-bottom: solid 1px;
					}
				}
			}
		}
	}
}
.franchise{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 90vh;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
		.title{
			position: absolute;
			z-index: 10; 
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			color:white;
			font-family: $playfair;
			font-size: 50px;
			font-weight: bold;
		}
	}
	.packages{
		margin: 100px 0;
		display: grid;
		grid-template-columns: 380px 1fr;
		grid-gap: 50px;
		.main-title{
			font-size: 50px;
			color: black;
			font-family: $playfair;
			font-weight: 700;
		}
		.group-package{
			.package{
				.title{
					font-size: 20px;
					color: black;
					font-weight: bold;
					border-bottom: solid 1px #707070;
					padding: 15px 0;
					width: 80%;
					.click{
						cursor: pointer;
						&:hover{
							opacity: .7;
						}
					}
					i{
						margin-left: 10px;
					}
				}
				.table{
					display: none;
				}
				table{
					width: 100%;
					border: solid 1px #EAEAEA;
					tr{
						color: black;
						th{
							// padding: 12px 30px;
							color: white;
							background-color: #212121;
						}
						td,th{
							padding: 12px 30px;
						}
						
					}
					tr:nth-child(odd){
						background-color: #EAEAEA;
					}
				}
			}
		}
	}
	.app{
		background-color: #EBEBEB;
		&:nth-child(even){
			background-color: white;
		}
		.application{
			margin: 100px 0;
			display: grid;
			grid-template-columns: 380px 1fr;
			grid-gap: 50px;
			.main-title{
				font-size: 50px;
				color: black;
				.title{
					font-family: $playfair;
					font-weight: 700;
				}
				.subtitle{
					font-size: 15px;
					color: #1A1A1A;
					font-family: $heebo;
					font-weight: 400;
					margin: 10px 0;
				}
			}
			.table{
				.data{
					display: grid;
					grid-template-columns: auto 1fr;
					grid-gap: 70px;
					margin-bottom: 30px;
					.num{
						color: #797979;
						font-size: 30px;
						line-height: 34px;
					}
					.desc{
						color: black;
						font-family: $heebo;
						.subject{ 
						font-size: 20px;
						font-weight: bold;
						}
						.action{
							font-size: 12px;
							margin-right: 10px;
						}
						a{
							font-weight: bold;
							font-size: 11px;
							&:hover{
								border-bottom: solid 1px black;
							}
							i{
								margin-left: 5px;
							}
						}
						.desc-data{
							font-size: 13px;
							color: black; 
							margin: 10px 0;
							display: none;
							font-family: $heebo;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.stores{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 300px;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
	}
	.locator{
		.main-title{
			letter-spacing: 1px;
			text-align: center;
			text-transform: uppercase;
			color: #707070;
			margin: 35px 0 25px;
			font-size: 12px;
			font-family: $heebo;
			font-weight: 700;
		}
		.search-form{
			text-align: center;
			input[type='text']{
				width: 500px;
				height: 48px;
				padding-left: 20px;
				font-size: 12px;
				border: solid 1px #C3C3C3;
				margin-right: 30px;
			}
			::placeholder{
				color: #C3C3C3;
			}
			button{
				height: 48px;
				padding: 0 40px;
				color: white;
				background-color: black;
				border: 0;
				border: solid 1px black;
				&:hover{
					background-color: #F0F0F0;
					color: black;
				}
			}
			button:focus, input[type='text']{
				outline: 0;
			}
		}
	}
	.locate{
		display: grid;
		grid-template-columns: 350px 1fr;
		margin: 70px 0;
		.text::-webkit-scrollbar {
			background-color:#fff;
			width: 15px
		}
		
		.text::-webkit-scrollbar-track {
			background-color:#fff
		}
		.text::-webkit-scrollbar-track:hover {
			background-color:#f4f4f4
		}
		.text::-webkit-scrollbar-thumb {
			background-color:#babac0;
			border-radius:16px;
			border:5px solid #fff
		}
		.text::-webkit-scrollbar-thumb:hover {
			background-color:#a0a0a5;
			border:4px solid #f4f4f4
		}
		.text::-webkit-scrollbar-button {display:none}
		.text{
			font-family: $heebo;
			height: 600px;
			overflow-y: scroll;
			.store-menu{
				display: flex;
				justify-content: space-around;
				margin: 20px 0;
				a{
					border: solid 1px;
					width: 95px;
					&:hover, &.active{
						background-color: #c3c3c3;
					}
				}
			}
			.branch{
				padding: 20px 10px;
				cursor: pointer;
				&:hover, &.active{
					background-color: #c3c3c3;
				}
				.title{
					font-weight: bold;
					font-size: 23px;
					color: black;
				}
				.address{
					margin: 6px 0;
				}
				.address,.store{
					color: #797979;
					font-size: 15px;
				}
				.bold{
					font-weight: bold;
				}
			}
			.more{
				font-family: $heebo;
				color: #00AEEF;
				font-weight: bold;
				padding: 20px 10px;
			}
		}
		.map{
			height: 600px;
			background-color: #c3c3c3;
		}
	}
}
.site-offers{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 300px;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
	}
	.offers{
		margin: 100px 0;
		.application{
			display: grid;
			grid-template-columns: 380px 1fr;
			grid-gap: 50px;
			.main-title{
				font-size: 50px;
				color: black;
				.title{
					font-family: $playfair;
					font-weight: 700;
				}
			}
			.table{
				.subtitle{
					font-size: 15px;
					color: #1A1A1A;
					font-family: $heebo;
					font-weight: 400;
					margin: 10px 0;
				}
				.data{
					display: grid;
					grid-template-columns: auto 1fr;
					grid-gap: 70px;
					margin-bottom: 30px;
					.num{
						color: #797979;
						font-size: 30px;
						line-height: 34px;
					}
					.desc{
						color: black;
						.subject{ 
						font-size: 18px;
						}
						.action{
							font-size: 12px;
							margin-right: 10px;
						}
						a{
							font-weight: bold;
							font-size: 14px;
							&:hover{
								border-bottom: solid 1px black;
							}
						}
						.desc-data{
							font-size: 18px;
							color: black; 
							font-family: $heebo;
							margin: 10px 0;
							ol{
								li{
									list-style-type: lower-alpha;
									p{
										margin: 10px 0l
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.news-features{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 300px;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
	}
	.news{
		.title{
			margin-top: 100px;
			font-size: 50px;
			text-align: center;
			color: black;
			font-family: $playfair;
			font-weight: 700;
		}
		.news-hover:hover{
			img{
				opacity: .7;
			}
			.text{
				background-color: #EAEAEA;;
			}
			
		}
		.feature{
			margin: 50px 0;
			img{
				object-fit: cover;
				object-position: center;
				height: 400px;
				width: 100%;
			}
			.text{
				border: solid 1px #EAEAEA;
				padding: 20px 100px;
				
				.date{
					color: #797979;
					font-size: 15px;
				}
				.main-title{
					font-size: 25px;
					font-weight: bold;
					margin: 15px 0;
				}
				.desc{
					color: #797979;
					font-size: 16px;
				}
			}
			// &:hover{
			// 	.text{
			// 		background-color: #F0F0F0;
			// 	}
			// }
		}
	}
}
.contact-page{
	position: relative;
	.main-banner{
		position: relative;
		.hero-banner{
			.banner{
				height: 300px;
				background-position: center;
				background-size: cover;
				display: flex;
				position: relative;
				width: 100%;
				align-items: center;
				&::before{
					position: absolute;
					top: -1px;
					bottom:-1px;
					left:-1px;
					right:-1px;
					content:"";
					opacity: .9;
					z-index:0;
					background: linear-gradient(to bottom, #000000 9%, transparent 125%);
				}
			}
		}
	}
	.contact{
		margin: 70px 0;
		margin-top: 80px;
		.title{
			font-size: 50px;
			text-align: center;
			color: black;
			font-weight: bold;
			font-family: $playfair;
		}
		.subtitle{
			color: #797979;
			text-align: center;
			font-size: 13px;
			font-family: $heebo;
		}
		.grid{
			display: grid;
			grid-template-columns: 1fr 350px;
			grid-gap: 100px;
			margin-top: 80px;
			padding: 0 100px;
			.form{
				::placeholder{
					font-size: 12px;
					opacity: .7;
				}
				.form-control{
					height:44px;
					border-radius: unset;
					box-shadow: unset;
				}
				input[type="submit"]{
					color: white;
					background: #191919;
					border: none;
					border-radius: unset;
					padding: 13px 36px;
					border: solid 1px #191919;
					&:hover{
						background-color: #F0F0F0;
						color: #191919;
					}
				}
				label{
					text-transform: uppercase;
					font-size: 11px;
					letter-spacing: 1.5px;
					cursor: pointer;
				}
				textarea{
					height: 80px !important;
					resize:none;
					padding-top: 12px;
				}
				select::placeholder{
					opacity: .7;
					color:white;
				}
			}
			.contact-email{
				background-color: #F0F0F0;
				.div{    
					text-align: center;
					margin: 30px 0;
					color: black;
					font-family: $heebo;
					.name{
						font-weight: bold;	
					}
					a:hover{
						opacity: .7;
					}
				}
			}
		}
	}
}
footer {
	background: #000000;
	padding: 50px 0;
	.footer-grid {
		.logo {
			display: flex;
			justify-content: center;
			img {
				max-width: 100%;
				max-height: 100px;
				&:hover{
					opacity: .5;
					transition: .5s;
				}
			}
		}
		.description{
			font-size: 11px;
			color: #797979;
			padding: 20px 200px;
			text-align: center;
		}
		.contact {
			color: #C3C3C3;
			.address {
				margin-bottom: 20px;
			}
			ul{
				margin-top: 18px;
				display: flex;
				justify-content: space-around;
				font-size: 11px;
				font-family: $heebo;
				a:hover{
					color: white;
				}
			}
		}
		.social {
			border-top: 1px solid #707070;
    		padding: 10px 0;
			font-family: 'Heebo', sans-serif;
			margin-top: 19px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			color: #797979;
			.design{
				font-size: 11px;
			}
			a{
				font-size: 11px;
			}
			a:hover{
				color: white;
			}
			ul {
				float: right;
				padding: 0;
				cursor: default;
				li {
					display: inline-block;
					font-size: 20px;
					padding-right: 10px;
				}
			}
		}
	}
}
.fab{
	font-size: 20px;
}

@media(max-width: 1360px){
	.home .about::before {
		right: 0;
	}
}
@media(max-width: 500px){
	.global-nav .navbar-brand img {
		max-height: 54px;
	}
	.menu-button{
		margin: 9px 20px;
	}
}
.pagination > .active > span{
	border-color: black;
	background-color: black;
}
.pagination > li > a{
	color: black;
}
@media(max-width: 650px){
	.news-features .news .feature .text {
		padding: 10px 10px;
	}
	.about-page{
		.title{
			font-size: 34px;
			line-height: 47px;
			img{
				height: 30px;
				margin-top: -25px;
			}
		}
		.history{
			margin: 20px 0;
		}
		.mission{	
			height: 330px;
		}
		.vision{
			height: 330px;
		}
		.news-gallery{
			margin: 50px 0;
		}
	}
}
@media (min-width: 1200px){
	.global-nav{
		.container {
			width: 1050px;
		}
	}
}
@media(max-width: 890px){
	.about-page .mission .container .quote img, .about-page .vision .container .quote img {
		height: 34px;
		margin-left: 0;
		margin-top: 0;
	}
	.footer-grid {
		grid-template-columns: 1fr !important;
		grid-gap: 10px;
		.logo{
			text-align: center;
		}
		.contact{
			ul{
				flex-direction: column;
				text-align: center;
				li{
					padding: 8px 0;
				}
			}
		}
	}
}
@media(max-width: 1020px){
	.gallery{
		display: grid;
		grid-template-columns: 1fr;
		height: auto;
		.image{
			height: 350px;
			.content{
				left: 0 !important;
				margin: 0 40px !important;
				.paragraph{
					padding: 20px 0 !important;
				}
			}
		}
	}
}
//button hovers;
@media (max-width: 1147px){
	.menu-button{
		display: block
	}
	.global-nav{
		.menu{
			text-align: center;
			background: white;
			box-shadow: -1px 0px 18px 0px rgba(0,0,0,0.75);
			position: absolute;
			margin-top: 100px;
			left: 0;
    		right: 0;
			z-index: 28;
			transform: translateY(-140%);
			&.open{
				transform: translateY(0%);
				transition: .5s;
			}
			ul{
			display: inline;
				a{
					line-height: unset;
					color: black;
					padding: 0;
					li{
					padding: 20px;
					&:hover{
						background-color:#C3C3C3;
					}
					}
				}
			}
		}
	}
}
@media (min-width: 1147px){
	.global-nav .menu ul {
		a::before, a::after{
			content:"";
			width: 0;
			height: 1px;
			position: absolute;
			transition: all 0.2s linear;
			background: #fff;
		  }
		  li::before, li::after{
			content:"";
			width:1px;
			height:0;
			position: absolute;
			transition: all 0.2s linear;
			background: #fff;
		  }
		  a:hover::before, a:hover::after{
			width: 100%;
		  }
		  a:hover li::before, a:hover li::after{
			height: 100%;
		  }
		  a::after{
			right:0;
			bottom: 0;
			transition-duration: 0.4s;
		  }
		  a li::after{
			right:0;
			bottom: 0;
			transition-duration: 0.4s;
		  }
		  a::before{
			left: 0;
			top: 0;
			transition-duration: 0.4s;
		  }
		  a li::before{
			left: 0;
			top: 0;
			transition-duration: 0.4s;
		  }
		  a{
			  &.active{
				  border: solid 1px white;
			  }
		  }
	}
	
	.btn-2:after,
	.btn-2:before{
	backface-visibility: hidden;
	
	border: 1px solid rgba(blue, 0);
	bottom: 0px;
	content: " ";
	display: block;
	margin: 0 auto;
	position: relative;
	transition: all 280ms ease-in-out;
	width: 0;
	}

	.btn-2:hover:after,
	.btn-2:hover:before{
	backface-visibility: hidden;
	border-color: white;
	transition: width 350ms ease-in-out;
	width: 70%;
	font-weight: bold;
	}

	.btn-2:hover:before{
	bottom: auto;
	top: 0;
	width: 70%;
	}
}
@media (max-width: 995px){
	.contact-page{
		.contact{
			.grid{
				padding: 0;
			}
		}
	}
	.stores{
		.locate{
			grid-template-columns: 1fr;
			grid-gap: 50px;
		}
	}
	.franchise{
		.packages{
			grid-template-columns: 1fr;
		}
		.app{
			.application {
			grid-template-columns: 1fr;
			.table{
				.data{
					grid-gap: 20px;
					}
				}
			}
		}
	}	
	.site-offers{
		.offers{
			.application {
			grid-template-columns: 1fr;
			grid-gap: 50px;
				.table{
					.data{
						grid-gap: 20px;
					}
				}
			}
		}
	}	
	.about-page{
		.history{
			.desc{
				column-count: 1;
				margin: 10px 0;
			}
		}
		.mission{
			.container{
				.quote{
				font-size: 3vh;
				}
			}
		}
		.vision{
			.container{ 
				.quote{
				font-size: 3vh;
				}
			}
		}
		.news-gallery{
			grid-template-columns: 1fr;
		}
	}



	.scrolled-nav .menu{
		margin-top: 60px !important;
	}
	.btn-2:hover{
		background-color: #a6a6a6;
		color: white !important;
	}
	
}
@media(max-width: 750px){
	.news .container .news-section, .home .container .gallery, .news-home .container .news-section{
		grid-template-columns: 1fr;
	}
	.home .container .gallery{
		height: 940px;
	}
	.contact-page .contact .grid {
		grid-template-columns: 1fr;
		.form{
			.submit{
				text-align: center;
			}
		}
	}
	
	.stores .locator .search-form {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 15px;
		input[type='text'] {
			width: 100%;
		}
	}
	.franchise .packages .group-package .package table tr td, .franchise .packages .group-package .package table tr th {
		padding: 5px 12px;
	}
	.franchise .packages .main-title, .franchise .app .application .main-title {
		font-size: 40px;
	}
	.about{
		.container{
			flex-direction: column;
			padding: 55px 10px;
			.title{
				text-align: center;
				padding-bottom: 25px;
			}
			.description{
				padding-left: 0 !important;
				font-family: 'Heebo', sans-serif;
			}
		}
	}
	footer .footer-grid .description {
		padding: 10px 10px;
	}
}
.slick-dots{
	bottom: 20px;
	li.slick-active button {
		opacity: 1;
	} 
}
.slick-next:before {
    content: '';
}
.slick-dots li button:before{
	font-size: 31px !important; 
	color: white !important; 	
}
.slick-dots li{
	width: 12px !important;
    height: 12px;
}
.slick-dots{
    width: 86px;
    transform: rotate(90deg);
    text-align: unset;
    position: unset;
    position: relative;
	top: 14px;
    left: -10px;
}
// 
.btn-2 {
	letter-spacing: 0;
	line-height: 45px;
	width: 100%;
}

.spinner{
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3E3E3E;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin-top: 300px;
	margin-left: auto;
	margin-right: auto;
}
.loader{
	position: absolute;
    height: 100%;
    width: 100%;
	z-index: 1;
	background-color: white;
    opacity: .7;
}
